// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum LinkTypes {
    /**
     * @description POST Request but fetching data
     */
    GET_MANUFACTURERS = '/car/car-manufacturer',
    GET_MODELS = '/car/car-models',
    GET_TRIM_LEVELS = '/car/car-trim-levels',
    GET_ESTEMATED_VALUE = '/car/estimated-value',
    GET_COUNTRIES = '/car/countries',
    GET_COUNTRIES_CODE = '/car/country-codes',
    GET_COMPARE_QOUTES = '/car/compare-lead-comp-quotes',
    GET_THIRD_PARTY = '/car/compare-lead-tpl-quotes',
    VEHICLE_DRIVER_DETAILS = '/car/vehicle-driver-details',
    GET_COMPARE_DATA = '/car/compare-data',
    GET_PAYFORT_DATA = '/car/payfort-data',
    GET_CUSTOMER = '/car/car-details',

    /**
     * @description Post Request Sending data to server
     */
    POST_VEHICLE_LEAD = '/car/car-lead',
    POST_DRIVER_DETAILS = '/car/add-driver-details',
    // POST_BUY_NOW = '/car/car-lead-buy-now-generate-qoute',
    POST_BUY_NOW = '/car/car-lead-buy-now-generate-temp-qoute',
    // POST_COUPON = '/car/car-coupon',
    POST_COUPON = '/car/coupon/validate',
    POST_CHECKOUT = '/car/temp-checkout',
    POST_CHEAPEST_QUOTES = '/car/send-cheapest-quotes',
    /*
      * @description Bike Post Request Sending data to server
    */
    GET_ENGINE_CAPCITY = '/car/get-engine-capacity',
    /*
      * @description Bike Post Request Sending data to server
    */
    POST_BIKE_LEAD = '/car/add-bike-lead',
    GET_BIKE_COMPARE_QUOTES = '/car/get-compare-tpl-quotes',

    POST_BIKE_CHEAPEST_QUOTES = '/car/send-bike-cheapest-quotes',
    
  }
  