import { BuyNow, Policy, Checkout } from '@/core/types/BuyNow';


export type State = {
  buyNow: BuyNow | null;
  checkout: Checkout | null;
  policy: Policy | null;
}

export const state: State = {
  buyNow: null,
  checkout: null,
  policy: null
};