import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import {store} from "@/store";
import ElementPlus from "element-plus";
import i18n from '@/core/plugins/i18n'
import { initComponent } from "./core/plugins/components";




import "bootstrap/dist/css/bootstrap.min.css"
import '@/assets/js/jquery.min.js';

import "bootstrap"
import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/display.css'

import ApiService from "@/core/services/ApiService";
import Manufacturers from "@/core/services/car/Manufacturers";
import Models from "@/core/services/car/Models";
import EstimatedValues from "@/core/services/car/EstimatedValues";
import Countries from "@/core/services/car/Countries";
import Drivers from "@/core/services/car/Drivers";
import Qoutes from "@/core/services/car/Qoutes";
import Currency from '@/core/services/etc/Currency';
import BuyNow from '@/core/services/car/BuyNow';
import Coupons from '@/core/services/car/Coupons';
import Language from '@/core/services/car/Language';
import Checkouts from '@/core/services/car/Checkouts';
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import Select2 from 'vue3-select2-component';

const langEn = require('@/assets/css/style-en.css');
const langAr = require('@/assets/css/style-ar.css');

const app = createApp(App)

app.config.globalProperties.yearList = {
  date(value: any) {
      return new Date(value);
    },
}

initComponent(app);
ApiService.init(app);
Manufacturers.init(app);
Models.init(app);
EstimatedValues.init(app);
Countries.init(app);
Drivers.init(app);
Qoutes.init(app);
BuyNow.init(app);
Coupons.init(app);
Language.init(app);
Checkouts.init(app);

app.config.globalProperties.$filters = {
    currency(value: number, spacing: number = 0) {
      return Currency.currencyFormater(value, spacing);
    }
  }


app
.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_V3,
  loaderOptions: {
    useRecaptchaNet: true
  }
})

if(window.localStorage.getItem('language') === 'ar') {
  app.use(langAr);
} else {
  app.use(langEn);
}

app.use(ElementPlus)
.use(i18n)
.use(store) 
.use(router);
// if(store) {
//     setTimeout(() => {
//       app.mount("#app");
//     }, 1000);
// }

router.isReady()
.then(() => {
  if(router.currentRoute.value && router.currentRoute.value.query.state === 'new') {
    // window.localStorage.removeItem('customer_detatils');
    const customers:any = window.localStorage.getItem('customer_detatils')
    const customer = JSON.parse(customers)
    if(customer) {
      customer.sid = ''
      window.localStorage.setItem('customer_detatils', JSON.stringify(customer))
    }
    window.localStorage.removeItem('driver_details');
    window.localStorage.removeItem('compare_data');
    window.localStorage.removeItem('buyNow');
    window.localStorage.removeItem('qoutes');
    window.localStorage.removeItem('fall_back');
    window.localStorage.removeItem('fall_back_dd');
  }
  app.mount("#app");

})

i18n.global.locale = store.state.lang.lang