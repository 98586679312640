import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { BuyNowMutationTypes } from '@/core/enums/BuyNow/Mutations';
import { BuyNowActionTypes } from '@/core/enums/BuyNow/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [BuyNowActionTypes.POST_BUY_NOW](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [BuyNowActionTypes.POST_BUY_NOW]({commit}, payload: any) {
    window.localStorage.removeItem('buyNow')
    window.localStorage.removeItem('qoutes')
    try {
      const data = await axios.post(LinkTypes.POST_BUY_NOW, payload);
      // console.log(data) 
      const invoice = data.data.data.invoice
      window.localStorage.setItem('fall_back_dd', invoice.uuid)

      delete invoice.uuid
      window.localStorage.setItem('buyNow', JSON.stringify(payload.value));
      window.localStorage.setItem('qoutes', JSON.stringify(invoice))
      commit(BuyNowMutationTypes.SET_CHECKOUT, invoice);
      commit(BuyNowMutationTypes.SET_SELECTED_POLICY, payload.value);
      return data;
    }
    catch(err) {
      console.log(err)
      return err;
    }
  },

};
