import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const host = window.location.host;
console.log(host,process.env.VUE_APP_CAR_DOMAIN)
const routes: Array<RouteRecordRaw> = [
  
  {
    path: "/",
    name: "Home",
    redirect: '/vehicle-details',
    component: () => (host == process.env.VUE_APP_CAR_DOMAIN) ? import("@/views/new/VehicleDetails.vue") : import("@/views/new/BikeDetails.vue"),
  },
  {
    path: "/vehicle-details",
    name: "VehicleDetails",
    component: () => (host == process.env.VUE_APP_CAR_DOMAIN) ? import("@/views/new/VehicleDetails.vue") : import("@/views/new/BikeDetails.vue"),
  },
  {
    path: "/driver-details",
    name: "DriverDetails",
    component: () => import("@/views/new/DriverDetails.vue"),
  },
  {
    path: "/result-listing",
    name: "QoutesDetails",
    component: () => import("@/views/new/ResultListing.vue"),
  },
  {
    path: "/compare-offers",
    name: "CompareOffer",
    component: () => import("@/views/new/CompareOffer.vue"),
  },
  {
    path: "/secure-checkout",
    name: "SecureChecout",
    component: () => import("@/views/new/Checkout.vue"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("@/views/PaymentDetails.vue"),
  },
  {
    path: "/payfort-process/:merchant",
    name: "Payfort",
    component: () => import("@/views/Token.vue"),
  },
  /* Bike Routes */
  {
    path: "/bike-details",
    name: "BikeDetails",
    component: () => import("@/views/new/BikeDetails.vue"),
  },
  {
    path: "/bike-result-listing",
    name: "BikeQuotesDetails",
    component: () => import("@/views/new/BikeResultListing.vue"),
  },
  {
    path: "/bike-secure-checkout",
    name: "BikeSecureCheckout",
    component: () => import("@/views/new/Checkout.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: -10 }
  },
});

export default router;
