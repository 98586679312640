import { App } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import selectDateV2 from '@/components/reusables/SelectDateV2.vue'

export function initComponent(app: App<Element>) {

    const data = [
        { key: 'vc-date-picker', component: selectDateV2 },
    ];

    data.forEach(element => {
        app.component(element.key, element.component);
    });

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}