import { Driver } from '@/core/types/Drivers';
import moment from 'moment';


export type State = {
  driver: Driver;
  loading: Boolean
}

export const state: State = {
  driver: {
      uuid: '',
      sid: '',
      dob: null,
      // dob_month: null,
      // dob_day: null,
      // dob_year: null,
      car_value: 0,
      first_registration_date: null,
      // first_registration_date_month: null,
      // first_registration_date_day: null,
      // first_registration_date_year: null,
      nationality: null,
      first_driving_license_country: null,
      driving_experience: 1,
      uae_driving_experience: 1,
      policy_start_date: moment().add(1, 'month').toString(),
      // policy_start_date_month: new Date().getMonth() + 1,
      // policy_start_date_day: new Date().getDay(),
      // policy_start_date_year: new Date().getFullYear(),
      claims: 0,
      claimed_insurance: 2,
      no_claim_certificate: 1,
      gcc_specification: 1,
      is_new: 1,
      personal_use: 1,
      current_policy_active: 1,
      fully_comprehensive: 1,
      third_party_liability: 2,
      existing_policy_expired: 2
  } as Driver,
  loading: false
};
