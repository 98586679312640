import { store } from "@/store";
import { App } from "vue";
import { VehicleActionTypes } from "@/core/enums/Vehicles/Actions";
import { VehicleMutationTypes } from '@/core/enums/Vehicles/Mutations';
import Models from "@/core/services/car/Models";
import TrimLevels from "@/core/services/car/TrimLevels";
import EstimatedValues from "@/core/services/car/EstimatedValues";

class Vehicles{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Vehicles.vueInstance = app;
    }

    public static async getDetails(payload: any) {
        return await store.dispatch(VehicleActionTypes.GET_CUSTOMER, payload);
    }

    public static async postVehicleLeads(payload: any) {
        return await store
        .dispatch(VehicleActionTypes.POST_VEHICLE_LEAD, payload)
    }

    public static async setVehicle(payload: any) {
        await store.commit(VehicleMutationTypes.SET_VEHICLE, payload)
    }

    public static getVehicleDetails(payload: any) {
        if(payload.manufacturer_id) {
            Models.getModelsByManufacturerId({year: payload.car_year, manufacturer_id: payload.manufacturer_id});
          }
          if(payload.model_id) {
            TrimLevels.getTrimLevelsByYearAndModelId({
              model_id: payload.model_id,
              year: payload.car_year,
            });
          }
          if(payload.trim_level_id) {
            EstimatedValues.getEstimatedValuesByYearManufacturerIdModelIdTrimLevelId(
              {
                car_year: payload.car_year,
                manufacturer_id: payload.manufacturer_id,
                model_id: payload.model_id,
                trim_level_id: payload.trim_level_id,
              }
            );
          }
    }

    public static async postBikeLeads(payload: any) {
        return await store
        .dispatch(VehicleActionTypes.POST_BIKE_LEAD, payload)
    }

    public static async setBike(payload: any) {
        await store.commit(VehicleMutationTypes.SET_BIKE, payload)
    }
}

export default Vehicles;