import { store } from "@/store";
import { App } from "vue";
import { TrimLevelsActionTypes } from "@/core/enums/TrimLevels/Actions";

class Models{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Models.vueInstance = app;
    }

    public static getTrimLevelsByYearAndModelId(padyload: object) {
        store
        .dispatch(TrimLevelsActionTypes.GET_TRIM_LEVELS, padyload)
    }

}

export default Models;