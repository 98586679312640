import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class ApiService {
    
    public static vueInstance: App;

    /**
     * 
     * @param app 
     * 
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL

        // Set up interceptor for request signing
        ApiService.signinRequest();
    }
    

    /**
     * Generate HMAC signature
     * @param message 
     * @param key 
     * @returns Promise<string>
     */
    private static async generateHmacSignature(message: string, key: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(message);
        const cryptoKey = await window.crypto.subtle.importKey("raw", encoder.encode(key), { name: "HMAC", hash: { name: "SHA-256" } }, false, ["sign"]);
        const signature = await window.crypto.subtle.sign("HMAC", cryptoKey, data);
        const array = new Uint8Array(signature);
        return Array.prototype.map.call(array, (x: any) => ('00' + x.toString(16)).slice(-2)).join('');
    }

    /**
     * Set up interceptor for request signing
     */
    private static signinRequest(): void {
        ApiService.vueInstance.axios.interceptors.request.use(async (config) => {

            const secretKey = process.env.VUE_APP_API_SECRET_KEY || 'secret_key';

            const nonce = Date.now().toString();
            const timestamp = Math.floor(Date.now() / 1000).toString(); // Convert to seconds

            const requestData = config.data ? JSON.stringify(config.data, (key, value) => value === null ? "" : value) : '';
            // Generate HMAC signature
            const message = nonce + timestamp + requestData;
            const signature = await ApiService.generateHmacSignature(message, secretKey);
            config.headers = config.headers || {};
            // Add signature headers to the request
            config.headers['X-BAI-Nonce'] = nonce;
            config.headers['X-BAI-Timestamp'] = timestamp;
            config.headers['X-BAI-Signature'] = signature;

            return config;
        }, error => {
            return Promise.reject(error);
        });
    }

    /**
     * 
     *  @description set header
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] = `application/json`
    }

    // /**
    //  * 
    //  */
    //  public static setFileHeader(): void {
    //     ApiService.vueInstance.axios.defaults.headers["Content-Type"] = `multipart/form-data`;
    //   }

    /**
     * 
     * @param resource 
     * @param params 
     * @returns Promise<AxiosResponse>
     */
    public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, params)
        .catch((err) => {
            throw new Error(`ApiService: ${err}`);
        });
    }

    /**
     * 
     * @param resource 
     * @param slug 
     * @returns Promise<AxiosResponse>
     */
    public static get(resource: string, slug = "" as string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(`${resource}/${slug}`)
        .catch((err) => {
            throw new Error(`ApiService ${err}`);
        })
    }

    /**
     * 
     * @param resource 
     * @param params 
     * @returns Promise<AxiosResponse>
     */
    public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    /**
     * 
     * @param resource 
     * @param slug 
     * @param params 
     * @returns 
     */
    public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * 
     * @param resource 
     * @param params 
     * @returns 
     */
    public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * 
     * @param resource 
     * @returns 
     */
    public static delete(resource: string): Promise<AxiosResponse>{
        return ApiService.vueInstance.axios.delete(resource)
        .catch((err) => {
            throw new Error(`ApiService: ${err}`)
        })
    }
 
}


export default ApiService;