import { Manufacturers } from '@/core/types/Manufacturers';

const language = window.localStorage.getItem('language');

export type State = {
  manufacturers: Array<Manufacturers>;
}

export const state: State = {
  manufacturers: [{value: -1, label: language === 'ar' ? 'نوع السيارة' : 'Car Make', id: 0, manufacturer: language === 'ar' ? 'نوع السيارة' :  'Car Make'}],
};
